.dotActive {
  width: 12px;
  height: 12px;
  background-color: #70c056;
  border-radius: 100%;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.5;
}

@keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.dotInactive {
  width: 12px;
  height: 12px;
  background-color: gray;
  border-radius: 100%;
}