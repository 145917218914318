.thumbnails-container {
  display: flex;
  flex-direction: wrap;
  flex-wrap: wrap;
  margin-top: 16px;
}

.thumbnail {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
}
.thumbnail__wrapper {
  display: flex;
  min-width: 0;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.thumbnail__close-button {
  position: absolute;
  top: -5px;
  right: -5px;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thumbnail__link {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.thumbnail__image {
  display: block;
  width: auto;
  height: 100%;
}
.thumbnail__icon {
  width: 100%;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 100%;
}