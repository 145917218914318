.avatarRoot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: 40px;
  height: 40px;
  max-width: 100%;
  max-height: 100%;
  background-color: transparent;
}

.avatarImage {
  width: 36px;
  height: 36px;
  object-fit: contain;
  border-radius: inherit;
}

.avatarFallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #84899C;
  line-height: 24px;
  font-size: 14px;
  border-radius: 100%;
  font-weight: 500;
  color: #fff;
}